<template>
  <b-modal
    id="loading"
    :visible="show"
    no-close-on-backdrop
    centered
    hide-footer
    hide-header
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.$bus.$on("showLoading", () => {
      this.show = true;
    });
    this.$bus.$on("hideLoading", () => {
      this.show = false;
    });
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.report_customer_detail {
  padding-right: 15px;
}
#loading {
  background: rgba(255, 255, 255, 0.39);
  .modal-content {
    background: unset;
    position: absolute;
    border: unset;
  }
  .modal-body {
    justify-content: center;
    align-content: center;
    display: flex;
  }
}
</style>
