var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center min-vh-100 login-box"},[_c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('c-col',{staticClass:"login",attrs:{"md":"9","lg":"6"}},[_c('b-card',{staticClass:"p-4 mb-0 card"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkForm.apply(null, arguments)}}},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("ADMIN LOGIN")])]),(
                _vm.settingAzure.enable_single_sign_on_azure_active_directory ===
                '1'
              )?_c('div',{class:['pt-4', { 'd-none': _vm.step !== 1 }]},[_c('ButtonLoginAzureAD',{attrs:{"clientId":_vm.settingAzure.client_id,"endpoint":_vm.settingAzure.azure_endpoint},on:{"handleMsgErr":_vm.handleMsgErr}}),_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"btn-link-custom",attrs:{"variant":"link"},on:{"click":function($event){return _vm.goToStep(2)}}},[_vm._v("Log in with Email")])],1)],1):_vm._e(),_c('div',{class:[{ 'd-none': _vm.step !== 2 }]},[_c('InputText',{staticClass:"my-4 login-input",attrs:{"textFloat":"Email","placeholder":"Email","type":"email","name":"email","isRequired":"","isValidate":_vm.$v.form.Email.$error,"v":_vm.$v.form.Email},on:{"onKeyup":_vm.submitFormOnInput},model:{value:(_vm.form.Email),callback:function ($$v) {_vm.$set(_vm.form, "Email", $$v)},expression:"form.Email"}}),_c('InputText',{staticClass:"my-4 login-input",attrs:{"textFloat":"Password","placeholder":"Password","type":"password","name":"password","isRequired":"","isValidate":_vm.$v.form.Password.$error,"v":_vm.$v.form.Password},on:{"onKeyup":_vm.submitFormOnInput},model:{value:(_vm.form.Password),callback:function ($$v) {_vm.$set(_vm.form, "Password", $$v)},expression:"form.Password"}}),_c('b-row',[_c('c-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"px-4 login-btn",attrs:{"type":"button","disabled":_vm.isDisable},on:{"click":_vm.checkForm}},[_vm._v("LOGIN")])],1)],1),(
                  _vm.settingAzure.enable_single_sign_on_azure_active_directory ===
                  '1'
                )?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"btn-link-custom",attrs:{"variant":"link"},on:{"click":function($event){return _vm.goToStep(1)}}},[_vm._v("Back to Main Page")])],1):_vm._e()],1)])],1)],1)],1)],1),_c('Loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }